import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { initialize } from 'react-ga';

import Layout from '../components/layout';
import Main from '../components/cahps-playbook/cahpsPlaybookMainContent';

/**
 * CAHPS Playbook page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <CAHPSPlaybook location={string} />
 */

const CAHPSPlaybook = ({ location }) => {
  const {
    contentfulAsset: { fixed },
  } = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq: "retention-cahps" }) {
        fixed(width: 850, quality: 85, resizingBehavior: SCALE) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
  `);
  const imgUrl = fixed.src.replace(
    /^(\/\/)(.*)(\.png|jpg|jpeg|svg|webp)(\?.*)$/g,
    'https://$2$3'
  );
  const imgType = fixed.src.replace(
    /^(\/\/)(.*)(png|jpg|jpeg|svg|webp)(\?.*)$/g,
    '$3'
  );
  const postImage = {
    height: '330',
    url: imgUrl,
    type: imgType,
    width: '800',
  };

  initialize('UA-33936956-01', {
    debug: true,
    titleCase: false,
  });

  return (
    <Layout location={location} postImage={postImage}>
      <Main location={location} />
    </Layout>
  );
};

CAHPSPlaybook.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default CAHPSPlaybook;
