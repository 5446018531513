import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validator from 'validator';
import { NetlifyForm, Honeypot } from 'react-netlify-forms';
import { event } from 'react-ga';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * CAHPS Playbook page Form component.
 * @component
 *
 * @param   {string} button       - Form submit button text
 * @param   {string} confirmation - Form submission confirmation message
 * @param   {string} heading      - Form heading copy
 * @returns {component}           - <Form button={string} confirmation={string} heading={string} />
 */

const Form = ({ button, confirmation, heading }) => {
  // eslint-disable-next-line no-unused-vars
  const [formName, setFormName] = useState('CAHPS Playbook');
  const [sent, setSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const validateEmail = evt => {
    const email = evt.target.value;

    if (email === '' || validator.isEmail(email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSent(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (sent) {
      event({
        category: 'Form',
        action: 'Submission',
        label: formName,
      });
    }
  }, [sent]);

  return (
    <div
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsStart,
        base.justifyCenter,
        base.w100,
        main.contactFormWrap
      )}
    >
      <h3 className={cx(base.mb4, theme.colorDark, main.heading)}>{heading}</h3>
      <NetlifyForm
        name={formName}
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyStart,
          base.w100,
          main.contactForm
        )}
        honeypotName="bot-field"
      >
        {({ handleChange, success, submitting }) => {
          if (success) {
            setSent(true);
          }

          return sent ? (
            <div
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.contactFormWrap
              )}
            >
              <p
                data-testid="confirmation"
                className={cx(
                  base.ma0,
                  base.tc,
                  base.w100,
                  main.contactFormConfirmation,
                  theme.colorTextGrey,
                  theme.title2
                )}
              >
                {confirmation}
              </p>
              <a
                href="/files/DP-Impacting-Perceptions-Playbook-2020.pdf"
                download="DP Impacting Perceptions Playbook 2020"
                target="_blank"
                className={cx(
                  base.mt5,
                  base.w100,
                  main.button,
                  main.contactButton,
                  theme.colorWhite,
                  theme.shapeBlue
                )}
              >
                Download
              </a>
            </div>
          ) : (
            <>
              <Honeypot />
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.mb3,
                  base.pb2,
                  base.relative,
                  base.w100
                )}
              >
                <label
                  htmlFor="email"
                  className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                >
                  Email<span className={theme.colorDanger}> *</span>
                </label>
                <input
                  id="email"
                  data-testid="email"
                  className={cx(base.w100, main.formInputField)}
                  type="text"
                  name="email"
                  inputMode="email"
                  required
                  onChange={evt => {
                    handleChange(evt);
                    validateEmail(evt);
                  }}
                  data-valid={`${!invalidEmail}`}
                />
                <span
                  className={cx(main.emailValidationError)}
                  aria-hidden={!invalidEmail}
                  style={{ display: invalidEmail ? 'block' : 'none' }}
                >
                  Invalid Email
                </span>
              </div>
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.mb3,
                  base.pb2,
                  base.w100
                )}
              >
                <label
                  htmlFor="first"
                  className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                >
                  First Name
                </label>
                <input
                  id="first"
                  className={cx(base.w100, main.formInputField)}
                  type="text"
                  name="first"
                  inputMode="text"
                  maxLength="80"
                  onChange={handleChange}
                />
              </div>
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.mb3,
                  base.pb2,
                  base.w100
                )}
              >
                <label
                  htmlFor="last"
                  className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                >
                  Last Name
                </label>
                <input
                  id="last"
                  className={cx(base.w100, main.formInputField)}
                  type="text"
                  name="last"
                  inputMode="text"
                  maxLength="80"
                  onChange={handleChange}
                />
              </div>
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.mb3,
                  base.pb2,
                  base.w100
                )}
              >
                <label
                  htmlFor="company"
                  className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                >
                  Company
                </label>
                <input
                  id="company"
                  className={cx(base.w100, main.formInputField)}
                  type="text"
                  name="company"
                  inputMode="text"
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                data-testid="submit"
                className={cx(
                  base.w100,
                  main.button,
                  main.contactButton,
                  theme.colorWhite,
                  theme.dpBtnBlue
                )}
                disabled={submitting || sent || invalidEmail}
              >
                {submitting ? 'Sending' : button}
              </button>
            </>
          );
        }}
      </NetlifyForm>
    </div>
  );
};

Form.propTypes = {
  button: PropTypes.string,
  confirmation: PropTypes.string,
  heading: PropTypes.string,
};

Form.defaultProps = {
  button: '',
  confirmation: '',
  heading: '',
};

export default Form;
