import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CleanHTML from '../cleanHTML';
import WistiaVideo from '../wistiaVideo';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * CAHPS Playbook page Hero component.
 * @component
 *
 * @param   {string} content - Raw html content
 * @param   {string} title   - Page title
 * @returns {component}      - <Hero content={string} title={string} />
 */

const Hero = ({ content, title }) => (
  <div
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyCenter,
      base.relative,
      base.w100,
      main.cahpsPlaybookWrap
    )}
  >
    <div
      className={cx(
        base.absoluteFill,
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.pb3,
        base.w100
      )}
    >
      {process.env.NODE_ENV === 'production' && (
        <WistiaVideo videoID="e6sf9l13h9" />
      )}
    </div>
    <h2
      className={cx(
        base.mb3,
        base.tc,
        main.memberTitle,
        main.heading,
        theme.colorBlue
      )}
    >
      {title}
    </h2>
    <CleanHTML html={content} />
  </div>
);

Hero.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Hero;
